import { get, omit } from 'lodash'
import * as React from 'react'
import { browserHistory } from 'react-router'

import {
    ActionBar,
    Button,
    CenterModal,
    Field,
    FieldCollection,
    Form,
    Input,
    MultiInput,
    PhoneInput,
    RadioButton,
    TextArea,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker } from '~/components/TagPicker'
import { Wrap } from '~/components/Wrap'
import { ASSIGNABLE_ROLES_BY_ROLE } from '~/shared/entities/user'
import { translateType, types } from '~/shared/utils'
import { Mutator, toast } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { VariableMultiInput } from '~/components/VariableMultiInput'
import { NT2CertificateFieldGroup } from '~/components/users/Teacher/NT2CertificateFieldGroup'
import { LessonCompetenceCertificateFieldGroup } from '~/components/users/Teacher/LessonCompetenceCertificateFieldGroup'
import { User } from '~/types/User'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import {
    EDIT_USER_MUTATION,
    RESET_COGNITO_TOTP_MFA_MUTATION,
} from '~/views/App/Users/Teachers/Detail/Edit/graphql/editUserMutation'
import { LocationFieldsInputGroup } from '~/components/Core/Form/LocationFieldsGroup/LocationFieldsInputGroup'
import { getCurrentUser } from '~/services/session'
import { ModalManager } from '~/components/ModalManager'
import { ChangeMobileMfaForm } from '~/forms/ChangeMobileMfaForm'
import { ChangeUserStatusForm } from '~/forms/ChangeUserStatus'

interface Props {
    user?: User
    params: {
        id: string
    }
    refetch?: (options?: any) => void
}

interface State {
    useAddressAsPaymentAddress?: boolean
    hasOwnCompany?: boolean
    isNT2Certified?: boolean
    isLessonCompetent?: boolean
    currentUser?: User
}

// TODO: Split into smaller render chunks
export default class EditView extends React.Component<Props, State> {
    public state: State = {
        useAddressAsPaymentAddress: undefined,
        hasOwnCompany: undefined,
        isNT2Certified: undefined,
        isLessonCompetent: undefined,
        currentUser: undefined,
    }

    private userMutator: Mutator
    private resetCognitoTotpMfaMutator: Mutator
    // private userDeleteMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.userMutator = new Mutator({
            mutation: EDIT_USER_MUTATION,
            reactComponentToUpdate: this,
        })

        this.resetCognitoTotpMfaMutator = new Mutator({
            mutation: RESET_COGNITO_TOTP_MFA_MUTATION,
            reactComponentToUpdate: this,
        })

        const user = getCurrentUser()

        this.state = {
            useAddressAsPaymentAddress: !get(props.user, 'teacher.paymentAddress'),
            hasOwnCompany: get(props.user, 'teacher.hasOwnCompany'),
            isNT2Certified: props.user && props.user.teacher && props.user.teacher.isNT2Certified,
            isLessonCompetent: props.user && props.user.teacher && props.user.teacher.lessonCompetence,
            currentUser: user,
        }
    }

    public render() {
        const { user, refetch } = this.props
        const { errors, loading } = this.userMutator
        const { useAddressAsPaymentAddress, hasOwnCompany, currentUser } = this.state
        const mapRole = (role: any) => ({ value: role, label: translateType('mutableRole', role) })
        let userMfaMobileNumber = ''
        let isMfaConfigured = 'Nee'
        let userStatus = 'Actief'
        let userTeacherIsActive = true
        if (user && user.teacher) {
            isMfaConfigured = user.profile.isMfaConfigured === true ? 'Ja' : 'Nee'
            userMfaMobileNumber = user.profile.mfaMobileNumber !== undefined ? user.profile.mfaMobileNumber : ''
            if (user.teacher.isActive !== undefined) {
                userStatus = user.teacher.isActive === true ? 'Actief' : 'Non-actief'
                userTeacherIsActive = user.teacher.isActive
            }
        }

        const sortAlphabetically = (a: any, b: any) => {
            if (a.label < b.label) {
                return -1
            }

            if (a.label > b.label) {
                return 1
            }

            return 0
        }

        const roleOptions = Object.keys(types.mutableRole)
            .filter(role => ASSIGNABLE_ROLES_BY_ROLE.TEACHER.includes(role))
            .map(mapRole)
            .sort(sortAlphabetically)
        const defaultMutableRoles = (get(user, 'roles') || []).map(mapRole)

        return (
            <ContentView hasFixedActionBar={true}>
                <Wrap>
                    <Form onSubmit={this.onSubmit}>
                        <FieldCollection errors={errors}>
                            <FieldGroup title={`Persoonlijke gegevens`} isForm={true}>
                                <Field title={`Naam`} errors={errors}>
                                    <MultiInput type={`name`}>
                                        <Input
                                            name={`user.profile.firstName`}
                                            type={`text`}
                                            placeholder={`Voornaam`}
                                            defaultValue={get(user, 'profile.firstName')}
                                        />
                                        <Input
                                            name={`user.profile.initials`}
                                            type={`text`}
                                            placeholder={`Voorletters`}
                                            defaultValue={get(user, 'profile.initials')}
                                        />
                                        <Input
                                            name={`user.profile.surNamePrefix`}
                                            type={`text`}
                                            placeholder={`Tussenvoegsel`}
                                            defaultValue={get(user, 'profile.surNamePrefix')}
                                        />
                                        <Input
                                            name={`user.profile.surName`}
                                            type={`text`}
                                            placeholder={`Achternaam`}
                                            defaultValue={get(user, 'profile.surName')}
                                        />
                                    </MultiInput>
                                </Field>
                                <Field title={`Geslacht`} errors={errors}>
                                    <MultiInput type={`radio`}>
                                        <RadioButton
                                            name={`user.profile.gender`}
                                            value={`MALE`}
                                            defaultChecked={get(user, 'profile.gender') === 'MALE'}
                                        >
                                            Man
                                        </RadioButton>
                                        <RadioButton
                                            name={`user.profile.gender`}
                                            value={`FEMALE`}
                                            defaultChecked={get(user, 'profile.gender') === 'FEMALE'}
                                        >
                                            Vrouw
                                        </RadioButton>
                                        <RadioButton
                                            name={`user.profile.gender`}
                                            value={`OTHER`}
                                            defaultChecked={get(user, 'profile.gender') === 'OTHER'}
                                        >
                                            Anders
                                        </RadioButton>
                                    </MultiInput>
                                </Field>
                            </FieldGroup>
                            {currentUser && currentUser.isAdmin && user && (
                                <FieldGroup title={`MFA`}>
                                    <Field isLabel title={`Geconfigureerd`}>
                                        {isMfaConfigured}
                                    </Field>
                                    <div>
                                        {user && user.profile.isMfaConfigured && user.profile.isMfaConfigured === true && (
                                            <Field isLabel title={`Reset MFA`}>
                                                <Button onClick={this.resetCognitoTotpMfa}>Reset</Button>
                                            </Field>
                                        )}
                                        <Field isLabel title={`Mobiel nummer voor MFA met SMS`}>
                                            <div>{userMfaMobileNumber}</div>
                                            <ModalManager
                                                render={openModal => <Button onClick={openModal}>Updaten</Button>}
                                                getModal={closeModal => (
                                                    <CenterModal
                                                        onClose={closeModal}
                                                        title={`Mobiel nummer voor MFA invoeren`}
                                                    >
                                                        <ChangeMobileMfaForm
                                                            onSubmitSuccess={() => {
                                                                closeModal()
                                                                if (refetch) {
                                                                    refetch()
                                                                }
                                                            }}
                                                            onCancel={closeModal}
                                                            userId={user._id}
                                                            mfaMobileNumberProps={userMfaMobileNumber}
                                                        />
                                                    </CenterModal>
                                                )}
                                            />
                                        </Field>
                                    </div>
                                </FieldGroup>
                            )}
                            <FieldGroup title={`Contactgegevens`} isForm={true}>
                                <Field title={`Telefoonnummer`} errors={errors}>
                                    <VariableMultiInput
                                        defaultAmount={get(user, 'profile.phoneNumbers.length')}
                                        getNewInput={i => (
                                            <PhoneInput
                                                key={i}
                                                name={`user.profile.phoneNumbers[${i}]`}
                                                defaultValue={get(user, `profile.phoneNumbers[${i}]`)}
                                            />
                                        )}
                                        addButtonLabel={`Extra veld`}
                                    />
                                </Field>
                                <Field isLabel={true} title={`Primair e-mailadres`} errors={errors}>
                                    <Input type={`email`} name={`user.email`} defaultValue={user && user.email} />
                                </Field>
                                <Field isLabel={true} title={`Secundair e-mailadres`} errors={errors}>
                                    <Input
                                        type={`email`}
                                        name={`user.profile.alternativeEmail`}
                                        defaultValue={get(user, `profile.alternativeEmail`)}
                                    />
                                </Field>

                                <LocationFieldsInputGroup
                                    errors={errors}
                                    title={'Adres'}
                                    location={user ? user.profile.address : undefined}
                                    withoutCountry={false}
                                    name={'user.profile.address'}
                                />
                            </FieldGroup>
                            <FieldGroup title={`Betalingsgegevens`} isForm={true}>
                                <Field title={`Adresgegevens als factuuradres gebruiken`}>
                                    <MultiInput type={`radio`}>
                                        <RadioButton
                                            name={`addressAsPrivateAddress`}
                                            defaultChecked={useAddressAsPaymentAddress}
                                            onClick={() => this.setState({ useAddressAsPaymentAddress: true })}
                                        >
                                            Ja
                                        </RadioButton>
                                        <RadioButton
                                            name={`addressAsPrivateAddress`}
                                            defaultChecked={!useAddressAsPaymentAddress}
                                            onClick={() => this.setState({ useAddressAsPaymentAddress: false })}
                                        >
                                            Nee
                                        </RadioButton>
                                    </MultiInput>
                                </Field>
                                {!useAddressAsPaymentAddress && (
                                    <FieldGroup isInsetGroup={true}>
                                        <LocationFieldsInputGroup
                                            errors={errors}
                                            title={'Factuuradres'}
                                            location={user && user.teacher ? user.teacher.paymentAddress : undefined}
                                            withoutCountry={false}
                                            name={'user.teacher.paymentAddress'}
                                        />
                                    </FieldGroup>
                                )}
                                {useAddressAsPaymentAddress && (
                                    <input type="hidden" name="user.teacher.paymentAddress" />
                                )}
                                <Field isLabel={true} title={`IBAN`} errors={errors}>
                                    <Input
                                        name={`user.teacher.paymentIban`}
                                        type={`text`}
                                        defaultValue={get(user, `teacher.paymentIban`)}
                                    />
                                </Field>
                            </FieldGroup>
                            <FieldGroup title={`Bedrijfsgegevens`} isForm={true}>
                                <Field title={`Is freelancer`}>
                                    <MultiInput type={`radio`}>
                                        <RadioButton
                                            name={`user.teacher.hasOwnCompany`}
                                            value={true}
                                            defaultChecked={hasOwnCompany}
                                            onClick={this.onHasOwnCompanyChange}
                                        >
                                            Ja
                                        </RadioButton>
                                        <RadioButton
                                            name={`user.teacher.hasOwnCompany`}
                                            value={false}
                                            defaultChecked={!hasOwnCompany}
                                            onClick={this.onHasOwnCompanyChange}
                                        >
                                            Nee
                                        </RadioButton>
                                    </MultiInput>
                                </Field>
                                {hasOwnCompany && (
                                    <FieldGroup isInsetGroup={true}>
                                        <Field isLabel={true} title={`Bedrijfsnaam`} errors={errors}>
                                            <Input
                                                name={`user.teacher.companyName`}
                                                type={'text'}
                                                defaultValue={get(user, 'teacher.companyName')}
                                            />
                                        </Field>
                                        <Field isLabel={true} title={`KvK-nummer`} errors={errors}>
                                            <Input
                                                name={`user.teacher.companyCocNumber`}
                                                type={'text'}
                                                defaultValue={get(user, 'teacher.companyCocNumber')}
                                            />
                                        </Field>
                                        <Field isLabel={true} title={`BTW-nummer`} errors={errors}>
                                            <Input
                                                name={`user.teacher.companyVatNumber`}
                                                type={'text'}
                                                defaultValue={get(user, 'teacher.companyVatNumber')}
                                            />
                                        </Field>
                                    </FieldGroup>
                                )}
                            </FieldGroup>
                            <FieldGroup title={`Overige`} isForm={true}>
                                <Field title="Personeelsnummer" errors={errors}>
                                    <Input
                                        type={`text`}
                                        name={`user.employee.hrNumber`}
                                        defaultValue={get(user, `employee.hrNumber`)}
                                    />
                                </Field>
                                <Field title="Functie(s)" errors={errors}>
                                    <TagPicker
                                        name={`user.mutableRoles`}
                                        options={roleOptions}
                                        placeholder={'Selecteer functies'}
                                        defaultValue={defaultMutableRoles}
                                    />
                                </Field>
                                {user && this.renderIsNT2CertifiedField(user, errors)}
                                {user && this.renderLessonCompetenceField(user, errors)}
                                <Field isLabel={true} title={`Notities`} errors={errors}>
                                    <TextArea name={`user.profile.notes`} defaultValue={user && user.profile.notes} />
                                </Field>
                                {user && (
                                    <Field title={`Status`} errors={errors}>
                                        <div>{userStatus}</div>
                                        <ModalManager
                                            render={openModal => <Button onClick={openModal}>Status wijzigen</Button>}
                                            getModal={closeModal => (
                                                <CenterModal onClose={closeModal} title={`Status wijzigen`}>
                                                    <ChangeUserStatusForm
                                                        onSubmitSuccess={() => {
                                                            closeModal()
                                                            if (refetch) {
                                                                refetch()
                                                            }
                                                        }}
                                                        onCancel={closeModal}
                                                        userId={user._id}
                                                        userStatus={userTeacherIsActive}
                                                    />
                                                </CenterModal>
                                            )}
                                        />
                                    </Field>
                                )}
                            </FieldGroup>
                        </FieldCollection>
                        <ActionBar
                            fixed={true}
                            getButtons={() => (
                                <List horizontal={true}>
                                    <ListItem right={true}>
                                        <Button type={`submit`} isLoading={loading}>
                                            Opslaan
                                        </Button>
                                    </ListItem>
                                    <ListItem right={true}>
                                        <Button
                                            onClick={() => browserHistory.push(`/users/teachers/${user && user._id}`)}
                                        >
                                            Annuleren
                                        </Button>
                                    </ListItem>
                                </List>
                            )}
                        />
                    </Form>
                </Wrap>
            </ContentView>
        )
    }

    private renderIsNT2CertifiedField = (user: User, errors: GraphQLErrors) => {
        const { isNT2Certified } = this.state

        return (
            <React.Fragment>
                <Field title={`Gecertificeerd NT2 docent?`} errors={errors}>
                    <MultiInput type={`radio`}>
                        <RadioButton
                            name={`user.teacher.isNT2Certified`}
                            value={true}
                            defaultChecked={isNT2Certified}
                            onChange={this.onIsNT2CertifiedChange}
                        >
                            Ja
                        </RadioButton>
                        <RadioButton
                            name={`user.teacher.isNT2Certified`}
                            value={false}
                            defaultChecked={!isNT2Certified}
                            onChange={this.onIsNT2CertifiedChange}
                        >
                            Nee
                        </RadioButton>
                    </MultiInput>
                </Field>
                {isNT2Certified && (
                    <FieldGroup isInsetGroup={true}>
                        <NT2CertificateFieldGroup
                            errors={errors}
                            defaultCertificateFiles={get(this.props, 'user.teacher.NT2Certificates')}
                            defaultDateValue={get(this.props, 'user.teacher.NT2CertifiedDate')}
                        />
                    </FieldGroup>
                )}
            </React.Fragment>
        )
    }

    private renderLessonCompetenceField = (user: User, errors: any[]) => {
        const { isLessonCompetent } = this.state

        return (
            <React.Fragment>
                <Field title={`Lesbevoegdheid`}>
                    <MultiInput type={`radio`}>
                        <RadioButton
                            name={`user.teacher.lessonCompetence`}
                            value={true}
                            defaultChecked={isLessonCompetent}
                            onChange={this.onLessonCompetenceChange}
                        >
                            Ja
                        </RadioButton>
                        <RadioButton
                            name={`user.teacher.lessonCompetence`}
                            value={false}
                            defaultChecked={!isLessonCompetent}
                            onChange={this.onLessonCompetenceChange}
                        >
                            Nee
                        </RadioButton>
                    </MultiInput>
                </Field>

                {isLessonCompetent && (
                    <FieldGroup isInsetGroup={true}>
                        <LessonCompetenceCertificateFieldGroup
                            defaultCertificateFiles={get(this.props, 'user.teacher.lessonCompetenceCertificates')}
                            defaultDateValue={get(this.props, 'user.teacher.lessonCompetenceDate')}
                            errors={errors}
                        />
                    </FieldGroup>
                )}
            </React.Fragment>
        )
    }

    private onSubmit = (event: React.FormEvent<HTMLFormElement>, fields: any) => {
        const { params, refetch } = this.props

        fields = omit(fields, ['addressAsPrivateAddress'])

        this.userMutator
            .mutate({
                user: {
                    _id: params.id,
                    ...transformFormFields(fields.user, {
                        mutableRoles: transformLib.split(),
                        profile: {
                            fields: (v: any) =>
                                transformFormFields(v, {
                                    phoneNumbers: transformLib.phoneNumbers(),
                                    address: transformLib.address(),
                                }),
                        },
                        teacher: {
                            fields: (v: any) =>
                                transformFormFields(v, {
                                    NT2CertifiedDate: transformLib.date(),
                                    lessonCompetenceDate: transformLib.date(),
                                    paymentAddress: transformLib.address(),
                                }),
                        },
                        employee: {
                            fields: (v: any) => transformFormFields(v),
                        },
                    }),
                },
            })
            .then(data => {
                if (data) {
                    if (refetch) {
                        refetch()
                    }
                    toast.success('Docent succesvol opgeslagen')
                    browserHistory.push(`/users/teachers/${params.id}`)
                }
            })
    }

    private onIsNT2CertifiedChange = ({ target }: any) => {
        this.setState({
            isNT2Certified: target.value === 'true' ? true : false,
        })
    }

    private onLessonCompetenceChange = ({ target }: any) => {
        this.setState({
            isLessonCompetent: target.value === 'true' ? true : false,
        })
    }

    private onHasOwnCompanyChange = ({ target }: any) => {
        this.setState({
            hasOwnCompany: target.value === 'true' ? true : false,
        })
    }

    private resetCognitoTotpMfa = () => {
        const { params, refetch } = this.props

        this.resetCognitoTotpMfaMutator
            .mutate({
                userId: params.id,
            })
            .then(data => {
                if (data) {
                    if (refetch) {
                        refetch()
                    }
                    toast.success('MFA reset is successful')
                    browserHistory.push(`/users/employees/${params.id}`)
                }
            })
    }
}
