import * as React from 'react'
import { Button, TableWrap, TableHeader, TableHeaderItem, Link, TableRow, Icon } from '~/components'
import gql from 'graphql-tag'
import Fetcher from '~/utils/Fetcher'
import Subtle from '~/components/Subtle'
import Sorter from '~/utils/Sorter'
import ReadableDate from '~/components/ReadableDate'
import { PlannedAbilityExam, Exam, ExamAbility } from '~/types/Exam'
const moment = require('moment')
import { Lesson } from '~/types/Lesson'
import { Group } from '~/types/Group'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import CenterModal from '~/components/CenterModal'
import { SelectExamLessonForm } from '~/forms/SelectExamLessonForm'
import { UpdateExamResultsModal } from '~/views/App/Groups/Started/Detail/Exams/partials/UpdateExamResultsModal'
import ToolTip from '~/components/ToolTip'
import { ExamResultsNeededIcon, ExamIsSoonIcon, ExamIsDoneIcon } from '~/components/ExamStatus/icons'
import { ContentView } from '~/components/ContentView'
import { TableView } from '~/components/TableView'
import { ModalManager } from '~/components/ModalManager'
import { getCurrentUser } from '~/services/session'

const DEFAULT_SORT_BY = 'exam.name'
const DEFAULT_SORT_DIR = 'DESC'

const examAbilityFragment = gql`
    fragment examAbility on ExamAbilityType {
        typeOfRating
        minimumScoreToPass
        maximumScore
        documentFiles {
            _id
            fileName
        }
    }
`

const GET_PLANNED_ABILITY_EXAMS = gql`
    query _($sortBy: String, $sortDir: String, $filterByGroupId: MongoID) {
        plannedAbilityExams(sortBy: $sortBy, sortDir: $sortDir, filterByGroupId: $filterByGroupId) {
            _id
            examAbility
            exam {
                _id
                name
                type
                level
                converse {
                    ...examAbility
                }
                talk {
                    ...examAbility
                }
                write {
                    ...examAbility
                }
                listen {
                    ...examAbility
                }
                read {
                    ...examAbility
                }
            }
            results {
                _id
                learnerUserId
                result {
                    passedNotPassed
                    score
                    level
                    status
                    absenceExplanation
                }
                files {
                    _id
                    fileName
                    mimeType
                }
            }
            group {
                _id
            }
            lesson {
                _id
                order
                date
                lessonUsers {
                    _id
                    learnerUserId
                    learnerUser {
                        _id
                        profile {
                            name
                        }
                    }
                }
                specialActivity
            }
            resultsCompleted
        }
    }

    ${examAbilityFragment}
`

interface Props {
    group: Group
    refetch?: () => void
}

interface State {
    sortDir: string
    sortBy: string
}

export default class ExamsView extends React.Component<Props, State> {
    public state: State = {
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private plannedAbilitiesFetcher: Fetcher
    private sorter: Sorter

    constructor(props: Props) {
        super(props)

        const { group } = this.props
        const { sortDir, sortBy } = this.state

        if (!group) {
            return
        }

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.plannedAbilitiesFetcher = new Fetcher({
            query: GET_PLANNED_ABILITY_EXAMS,

            variables: {
                sortDir,
                sortBy,
                filterByGroupId: group._id,
            },
            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        return <ContentView>{this.renderTable()}</ContentView>
    }

    private renderTable = () => {
        const { data, loading } = this.plannedAbilitiesFetcher
        const { plannedAbilityExams = [] } = data
        const { sorter } = this

        return (
            <TableWrap>
                <TableView>
                    <Table>
                        <TableHeader>
                            <TableHeaderItem sorter={sorter} sortBy={`examAbility`} width="26%">
                                Vaardigheid
                            </TableHeaderItem>
                            <TableHeaderItem>Type</TableHeaderItem>
                            <TableHeaderItem sorter={sorter} sortBy={`exam.name`}>
                                Toetsnaam
                            </TableHeaderItem>
                            <TableHeaderItem sorter={sorter} sortBy={`lesson.date`}>
                                Toetsdatum
                            </TableHeaderItem>
                            <TableHeaderItem />
                        </TableHeader>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={5} isLoading={true} />
                            </TableRow>
                        ) : plannedAbilityExams.length > 0 ? (
                            this.renderTableRows(plannedAbilityExams)
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Subtle>Er zijn geen toetsen gevonden.</Subtle>
                                </TableCell>
                            </TableRow>
                        )}
                    </Table>
                </TableView>
            </TableWrap>
        )
    }

    private renderTableRows = (plannedAbilityExams: PlannedAbilityExam[]) => {
        const { group } = this.props

        return plannedAbilityExams.map((plannedAbilityExam, i) => {
            if (!plannedAbilityExam) {
                return null
            }

            const examAbility = plannedAbilityExam.examAbility
            const exam = plannedAbilityExam.exam
            const lesson = plannedAbilityExam.lesson
            const resultsCompleted = plannedAbilityExam.resultsCompleted

            const lessonDateIsKnown = !!lesson && !!lesson.date
            const specialActivity = lesson && lesson.specialActivity
            const examAbilityDetails = exam && exam[examAbility]

            if (!exam || !group) {
                return null
            }

            return (
                <TableRow key={`row-${i}`}>
                    <TableCell hasInlineIcon={true} width="26%">
                        {this.getReadableExamAbility(examAbility)}
                        {this.renderIcon(resultsCompleted, lesson)}
                    </TableCell>
                    <TableCell>{this.getReadableActivity(specialActivity)}</TableCell>
                    <TableCell>
                        {this.userMayViewExamDetail() ? (
                            <Link route={`/properties/exams/${exam._id}`}>{exam.name}</Link>
                        ) : (
                            exam.name
                        )}
                    </TableCell>
                    <TableCell>
                        {lessonDateIsKnown && !!lesson && (
                            <React.Fragment>
                                {this.userMayViewLessonDetail() ? (
                                    <Link route={`/groups/${group._id}/lessons/${lesson._id}`}>
                                        {this.getLessonText(lesson)}
                                    </Link>
                                ) : (
                                    this.getLessonText(lesson)
                                )}
                                {' ('}
                                <ReadableDate date={lesson.date} showTime={true} />
                                {')'}
                            </React.Fragment>
                        )}
                        {!lessonDateIsKnown && 'Nog niet ingepland'}
                    </TableCell>
                    <TableCell>
                        {!lessonDateIsKnown && this.userMayPlanExam() && (
                            <ModalManager
                                render={openModal => {
                                    return (
                                        <Button small={true} onClick={openModal} leftIcon={<Icon name={`calendar`} />}>
                                            Toets inplannen
                                        </Button>
                                    )
                                }}
                                getModal={closeModal => this.renderPlanExamModal(closeModal, exam, examAbility)}
                            />
                        )}
                        {lessonDateIsKnown && examAbilityDetails && this.userMayEnterResults() && (
                            <ModalManager
                                render={openModal => {
                                    return (
                                        <Button small={true} onClick={openModal}>
                                            {resultsCompleted ? 'Resultaten bewerken' : 'Resultaten invullen'}
                                        </Button>
                                    )
                                }}
                                getModal={closeModal => (
                                    <UpdateExamResultsModal
                                        plannedAbilityExam={plannedAbilityExam}
                                        examAbilityDetails={examAbilityDetails}
                                        onSubmitSuccess={() => {
                                            closeModal()
                                            this.plannedAbilitiesFetcher.refetch()
                                        }}
                                        onCancel={() => {
                                            closeModal()
                                        }}
                                    />
                                )}
                            />
                        )}
                    </TableCell>
                </TableRow>
            )
        })
    }

    private getReadableActivity = (specialActivity?: string) => {
        if (!specialActivity) {
            return null
        }

        const isActivityExam = specialActivity === 'exam'
        const isActivityAssessment = specialActivity === 'assessment'
        const isActivityReportMoment = specialActivity === 'reportMoment'
        const isActivityPortfolioDeadline = specialActivity === 'portfolioDeadline'

        if (isActivityExam) {
            return 'Toets'
        } else if (isActivityAssessment) {
            return 'Voortgangsgesprek'
        } else if (isActivityReportMoment) {
            return 'Rapportagemoment'
        } else if (isActivityPortfolioDeadline) {
            return 'Deadline portfolio'
        }

        return null
    }

    private renderPlanExamModal = (closeModal: () => void, exam: Exam, examAbility: ExamAbility): React.ReactNode => {
        const { group } = this.props

        return (
            <CenterModal small={true} onClose={closeModal} title="Toets inplannen">
                <SelectExamLessonForm
                    onClose={closeModal}
                    examId={exam._id}
                    examAbility={examAbility}
                    groupId={group._id}
                    onSubmitSuccess={() => {
                        closeModal()
                        this.plannedAbilitiesFetcher.refetch()
                    }}
                />
            </CenterModal>
        )
    }

    private renderIcon = (resultsCompleted: boolean, lesson?: Lesson) => {
        const lessonDate = lesson && lesson.date && moment(lesson.date)
        const now = moment()
        const daysToExamLesson = lessonDate && lessonDate.diff(now, 'hours')
        const isLessonDateWithinAWeek = daysToExamLesson >= 0 && daysToExamLesson < 24 * 7

        if (!lessonDate) {
            return null
        }

        if (resultsCompleted === true) {
            return (
                <ToolTip text={`De toets is afgerond`}>
                    <ExamIsDoneIcon />
                </ToolTip>
            )
        }

        if (isLessonDateWithinAWeek && resultsCompleted === false) {
            return (
                <ToolTip text={`De toets is binnenkort`}>
                    <ExamIsSoonIcon />
                </ToolTip>
            )
        }

        if (!isLessonDateWithinAWeek && resultsCompleted === false && daysToExamLesson < 0) {
            return (
                <ToolTip text={`Er moeten resultaten worden ingevoerd`}>
                    <ExamResultsNeededIcon />
                </ToolTip>
            )
        }

        return null
    }

    private sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.plannedAbilitiesFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }

    private getReadableExamAbility = (abilityName?: string) => {
        switch (abilityName) {
            case 'converse':
                return 'Gespreksvaardigheid'
            case 'talk':
                return 'Spreken'
            case 'write':
                return 'Schrijven'
            case 'listen':
                return 'Luisteren'
            case 'read':
                return 'Lezen'
            default:
                return null
        }
    }

    private getLessonText(lesson: Lesson): React.ReactNode {
        return `Les ${lesson.order + 1}`
    }

    private userMayPlanExam(): boolean {
        const currentUser = getCurrentUser()

        return currentUser.isEmployee
    }

    private userMayEnterResults(): boolean {
        const currentUser = getCurrentUser()

        return currentUser.isEmployee || currentUser.isExternalTeacher
    }

    private userMayViewLessonDetail(): boolean {
        const currentUser = getCurrentUser()

        return currentUser.isEmployee
    }

    private userMayViewExamDetail(): boolean {
        const currentUser = getCurrentUser()

        return currentUser.isEmployee
    }
}
