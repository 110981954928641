// tslint:disable:max-line-length
import * as React from 'react'
import Form, { FormFields } from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button, Field, Select, Option } from '~/components'
import gql from 'graphql-tag'
import { Mutator } from '~/utils'
import { RootMutationTypeExports_Teachers_HoursArgs } from '~/generated/graphql'
import { range } from 'lodash'


const MUTATION = gql`
    mutation exports_teachers_hours($year: Int) {
        exports_teachers_hours(year: $year) {
            _id
            file {
                _id
                fileName
            }
        }
    }
`

interface Props {
    onClose: () => void
    onSubmitSuccess: (fileId: string, filename: string) => void
}

export class TeachersHoursExportForm extends React.Component<Props> {
    private mutator: Mutator

    private YEAR_OPTIONS = range(new Date().getFullYear() - 2, new Date().getFullYear() + 1) // range function excludes the last value

    constructor(props: Props) {
        super(props)

        this.mutator = new Mutator({
            mutation: MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onClose } = this.props
        const { loading, errors } = this.mutator

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style="modal">
                    <FieldGroup isForm={true}>
                        <Field title={`Kalenderjaar`} errors={errors}>
                            <Select name={`year`}>
                                <Option value={''}>Kalenderjaar</Option>
                                {this.YEAR_OPTIONS.map((year, index) => (
                                    <Option key={index} value={year}>
                                        {year === new Date().getFullYear() ? `${year} tot heden` : year}
                                    </Option>
                                ))}
                            </Select>
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button type="button" onClick={onClose}>
                                    Annuleren
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button styleOverride="edit" type="submit" isLoading={loading}>
                                    Genereer export
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { onSubmitSuccess } = this.props

        const formFields: RootMutationTypeExports_Teachers_HoursArgs = {
            year: fields.year,
        }

        const result = await this.mutator.mutate(formFields)

        if (result && result.exports_teachers_hours) {
            const { _id, fileName } = result.exports_teachers_hours.file
            onSubmitSuccess(_id, fileName)
        }
    }
}
