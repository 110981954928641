// tslint:disable:max-line-length
import * as React from 'react'
import Form, { FormFields } from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button, DatePicker, Field, Hint, MultiInput, RadioButton, TextArea } from '~/components'
import gql from 'graphql-tag'
import { Mutator } from '~/utils'
import { RootMutationTypeExports_Wi2021_Students_And_HoursArgs } from '~/generated/graphql'

const MUTATION = gql`
    mutation exports_wi2021_students_and_hours(
        $automaticStudentSearch: Boolean
        $countUntilDate: Date
        $bsnList: [String!]
    ) {
        exports_wi2021_students_and_hours(
            automaticStudentSearch: $automaticStudentSearch
            countUntilDate: $countUntilDate
            bsnList: $bsnList
        ) {
            _id
            file {
                _id
                fileName
            }
        }
    }
`

interface Props {
    onClose: () => void
    onSubmitSuccess: (fileId: string, filename: string) => void
    disableAutomaticStudentSearch?: boolean
}

interface State {
    automaticStudentSearch: boolean
}

export class WI2021StudentsAndHoursExportForm extends React.Component<Props, State> {
    private mutator: Mutator

    constructor(props: Props) {
        super(props)

        this.mutator = new Mutator({
            mutation: MUTATION,
            reactComponentToUpdate: this,
        })

        this.state = {
            automaticStudentSearch: !this.props.disableAutomaticStudentSearch,
        }
    }

    public render() {
        const { onClose } = this.props
        const { automaticStudentSearch } = this.state
        const { loading, errors } = this.mutator

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style="modal">
                    <FieldGroup isForm={true}>
                        <Field title={`Uren tellen tot en met datum`} errors={errors}>
                            <DatePicker name={'countUntilDate'} />
                        </Field>
                        <Field title={`Zoekopdracht studenten`} errors={errors}>
                            <MultiInput type={`radio`}>
                                <RadioButton
                                    value={true}
                                    checked={automaticStudentSearch}
                                    onChange={this.onChangeAutomaticStudentSearch}
                                    isDisabled={this.props.disableAutomaticStudentSearch}
                                >
                                    Automatisch
                                    <Hint>
                                        WI2021-kandidaten die in de 3 maanden voorafgaand aan aangegeven datum inburgeringsles
                                        hebben gehad van type “Standaard”, “ONA”, “Staatsexamen” of “Alfabetisering”.
                                    </Hint>
                                </RadioButton>
                                <RadioButton
                                    value={false}
                                    checked={!automaticStudentSearch}
                                    onChange={this.onChangeAutomaticStudentSearch}
                                >
                                    Handmatig
                                <Hint>Eén of meerdere BSN&apos;s van WI2021-kandidaten</Hint>
                            </RadioButton>
                            </MultiInput>
                        </Field>
                        {!automaticStudentSearch && (
                            <FieldGroup isInsetGroup={true}>
                                <Field title="BSN's" errors={errors}>
                                    <TextArea
                                        name="bsnInput"
                                        errorKey="bsnList"
                                        placeholder="(komma- of entergescheiden)"
                                    />
                                </Field>
                            </FieldGroup>
                        )}
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button type="button" onClick={onClose}>
                                    Annuleren
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button styleOverride="edit" type="submit" isLoading={loading}>
                                    Genereer export
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onChangeAutomaticStudentSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            automaticStudentSearch: event.target.value === 'true',
        })
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { onSubmitSuccess } = this.props
        const { automaticStudentSearch } = this.state

        const formFields: RootMutationTypeExports_Wi2021_Students_And_HoursArgs = {
            automaticStudentSearch,
            countUntilDate: fields.countUntilDate && new Date(fields.countUntilDate),
        }

        if (!automaticStudentSearch) {
            formFields.bsnList = (fields.bsnInput || '')
                .split(/[\s,\n]+/) // Split on whitespace, commas, or newlines
                .filter((bsn: string) => bsn.length > 0) // Remove empty strings
        }

        const result = await this.mutator.mutate(formFields)

        if (result && result.exports_wi2021_students_and_hours) {
            const { _id, fileName } = result.exports_wi2021_students_and_hours.file
            onSubmitSuccess(_id, fileName)
        }
    }
}
