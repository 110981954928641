export const ASSIGNABLE_ROLES_BY_ROLE = {
    LEARNER: [],
    ORGANIZATION_CONTACT: [],
    TEACHER: ['INTAKER'],
    EMPLOYEE: [
        'ADMIN',
        'INTAKER',
        'INTERNAL_TEACHER',
        'PROJECT_MANAGER',
        'PROJECT_ASSISTANT',
        'TEAM_LEADER',
        'RECEPTIONIST',
        'MANAGEMENT',
        'OFFICE_ASSISTENT',
        'ACCOUNTANT',
        'TELEPHONIST',
        'FINANCIAL_EMPLOYEE',
        'FACILITY_EMPLOYEE',
        'ON_CALL_EMPLOYEE',
        'MANAGER',
        'IT_EMPLOYEE',
        'ACCOUNT_MANAGER',
        'INTAKE_EMPLOYEE',
        'HR_EMPLOYEE',
    ],
}
