import * as React from 'react'
import { browserHistory } from 'react-router'

import { translateType } from '~/shared/utils'
import {
    ActionBar,
    AddressFieldGroup,
    Button,
    Field,
    FieldCollection,
    CenterModal,
    ReadablePhoneNumbers,
    PdfModal,
    ReadableDate,
    Subtle,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { ModalManager } from '~/components/ModalManager'
import { Wrap } from '~/components/Wrap'
import { UserInvitationForm } from '~/forms'
import { User, CertificateFile, UserRole } from '~/types/User'
import downloadFile from '~/utils/downloadFile'
import EmployeeExternalTeacherForm from '~/forms/EmployeeExternalTeacherForm'
import { viewerHasRole } from '~/services/session'

interface Props {
    user?: User
    params: {
        id: string
    }
    refetch?: (options?: any) => void
}

export default class DataView extends React.Component<Props> {
    public render() {
        const { user } = this.props

        if (!user) {
            return null
        }
        let isMfaConfigured = 'Nee'
        isMfaConfigured = user.profile.isMfaConfigured === true ? 'Ja' : 'Nee'
        const phoneNumbers = user.profile.phoneNumbers || []

        return (
            <ContentView hasFixedActionBar={true}>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup title={`Persoonlijke gegevens`}>
                            <Field title={`Naam`}>{user.profile.name}</Field>
                            <Field title={`Geslacht`}>{translateType('userGender', user.profile.gender)}</Field>
                        </FieldGroup>
                        <FieldGroup title={`MFA`}>
                            <Field title={`Geconfigureerd`}>{isMfaConfigured}</Field>
                        </FieldGroup>
                        <FieldGroup title={`Contactgegevens`}>
                            <Field title={`Telefoonnummer`}>
                                <ReadablePhoneNumbers phoneNumbers={phoneNumbers} />
                            </Field>
                            <Field title={`Primair e-mailadres`}>{user.email}</Field>
                            {user.email && !user.lastActivityAt && !user.invitedAt && (
                                // todo: think about how we could retrigger cognito
                                <Field title="&nbsp;">
                                    <ModalManager
                                        render={openModal => (
                                            <Button onClick={openModal}>{'Toegang tot het systeem verlenen'}</Button>
                                        )}
                                        getModal={closeModal => (
                                            <CenterModal
                                                onClose={closeModal}
                                                title={`Toegang verlenen aan ${user.profile.name}`}
                                            >
                                                <UserInvitationForm
                                                    user={user}
                                                    onSuccess={() => {
                                                        closeModal()
                                                        this.userInvited()
                                                    }}
                                                    onCancel={closeModal}
                                                />
                                            </CenterModal>
                                        )}
                                    />
                                </Field>
                            )}
                            <Field title={`Secundair e-mailadres`}>{user.profile.alternativeEmail}</Field>
                            <AddressFieldGroup user={user} />
                        </FieldGroup>
                        {this.renderRemainderFieldGroup(user)}
                    </FieldCollection>
                    <ActionBar
                        fixed={true}
                        getButtons={() => (
                            <List horizontal={true}>
                                <ListItem>
                                    <ModalManager
                                        render={openModal =>
                                            viewerHasRole(UserRole.Admin) && (
                                                <Button styleOverride={`secondary-edit`} onClick={openModal}>
                                                    Wijzigen naar externe docent
                                                </Button>
                                            )
                                        }
                                        getModal={closeModal => (
                                            <CenterModal onClose={closeModal} title={`Wijzigen naar externe docent`}>
                                                <EmployeeExternalTeacherForm
                                                    type="employeeToTeacher"
                                                    onSubmitSuccess={() => {
                                                        closeModal()
                                                        browserHistory.push(`/users/teachers/${user._id}`)
                                                    }}
                                                    onCancel={closeModal}
                                                    user={user}
                                                />
                                            </CenterModal>
                                        )}
                                    />
                                </ListItem>
                                <ListItem right={true}>
                                    <Button
                                        type={`edit`}
                                        onClick={() => browserHistory.push(`/users/employees/${user._id}/edit`)}
                                    >
                                        Bewerken
                                    </Button>
                                </ListItem>
                            </List>
                        )}
                    />
                </Wrap>
            </ContentView>
        )
    }

    private renderRemainderFieldGroup = (user: User) => {
        const { teacher } = user

        const teacherIsNT2Certified = teacher && teacher.isNT2Certified
        const NT2Certificates = teacher && teacher.NT2Certificates
        const NT2CertifiedDate = teacher && teacher.NT2CertifiedDate

        const teacherHasLessonCompetence = teacher && teacher.lessonCompetence
        const lessonCompetenceCertificates = teacher && teacher.lessonCompetenceCertificates
        const lessonCompetenceDate = teacher && teacher.lessonCompetenceDate
        const userRoles = user.roles || []

        return (
            <FieldGroup title={`Overige`}>
                <Field title={`Personeelsnummer`}>{user.employee && user.employee.hrNumber}</Field>

                <Field title={`Functie(s)`}>
                    {userRoles.map(role => translateType('mutableRole', role)).join(', ')}
                </Field>

                {userRoles.includes(UserRole.InternalTeacher) && (
                    <>
                        <Field title={`Gecertificeerd NT2 docent?`}>
                            {teacherIsNT2Certified ? 'Ja' : 'Nee'}

                            <List>
                                {teacherIsNT2Certified && NT2CertifiedDate && (
                                    <ListItem>
                                        <Subtle isBlockElement={true}>Certificeringsdatum</Subtle>
                                        <ReadableDate date={NT2CertifiedDate} />
                                    </ListItem>
                                )}

                                {teacherIsNT2Certified && NT2Certificates && (
                                    <ListItem>
                                        {this.renderCertificateFields('NT2 certificaat', NT2Certificates)}
                                    </ListItem>
                                )}
                            </List>
                        </Field>

                        <Field title={`Lesbevoegdheid`}>
                            {teacherHasLessonCompetence ? 'Ja' : 'Nee'}

                            <List>
                                {teacherIsNT2Certified && lessonCompetenceDate && (
                                    <ListItem>
                                        <Subtle isBlockElement={true}>Certificeringsdatum</Subtle>
                                        <ReadableDate date={lessonCompetenceDate} />
                                    </ListItem>
                                )}

                                {teacherHasLessonCompetence && lessonCompetenceCertificates && (
                                    <ListItem>
                                        {this.renderCertificateFields(
                                            'Lesbevoegheid certificaat',
                                            lessonCompetenceCertificates
                                        )}
                                    </ListItem>
                                )}
                            </List>
                        </Field>
                    </>
                )}

                <Field title={`Notities`}>{user.profile.notes}</Field>
                <Field title={`Status`}>
                    {user && user.employee && user.employee.isActive ? 'Actief' : 'Non-actief'}
                </Field>
            </FieldGroup>
        )
    }

    private renderCertificateFields = (title: string, certificates: CertificateFile[]) => {
        return (
            <>
                <Subtle isBlockElement={true}>{certificates.length === 1 ? 'Certificaat' : 'Certificaten'}</Subtle>
                <List>
                    {certificates.map(file => {
                        const { _id, fileName } = file

                        return file.isPDF ? (
                            <ListItem key={`file-${_id}`}>
                                <ModalManager
                                    render={openModal => (
                                        <Button onClick={openModal} linkStyle={`default`}>
                                            {fileName}
                                        </Button>
                                    )}
                                    getModal={closeModal => (
                                        <PdfModal
                                            title={fileName}
                                            fileName={fileName}
                                            getFileId={() => _id}
                                            onClose={closeModal}
                                        />
                                    )}
                                />
                            </ListItem>
                        ) : (
                            <ListItem key={`file-${_id}`}>
                                <Button linkStyle={`default`} onClick={() => downloadFile(_id, fileName)}>
                                    {fileName}
                                </Button>
                            </ListItem>
                        )
                    })}
                </List>
            </>
        )
    }

    private userInvited = () => {
        const { refetch } = this.props

        if (refetch) {
            refetch({ silent: true })
        }
    }
}
