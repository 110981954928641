import * as React from 'react'
import gql from 'graphql-tag'
import { browserHistory } from 'react-router'

import { Mutator, downloadFile, toast } from '~/utils'
import { translateType } from '~/shared/utils'
import {
    ActionBar,
    AddressFieldGroup,
    Bold,
    Button,
    Field,
    FieldCollection,
    Hint,
    CenterModal,
    ReadableAddress,
    ReadablePhoneNumbers,
    Subtle,
    PdfModal,
    ReadableDate,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { ModalManager } from '~/components/ModalManager'
import { Wrap } from '~/components/Wrap'
import { UserInvitationForm } from '~/forms'
import { User, CertificateFile, UserRole } from '~/types/User'
import EmployeeExternalTeacherForm from '~/forms/EmployeeExternalTeacherForm'
import { viewerHasRole } from '~/services/session'

const SEND_ATTENDANCE_REMINDER_MUTATION = gql`
    mutation _($teacherUserId: MongoID!) {
        users_sendAttendanceReminder(teacherUserId: $teacherUserId) {
            _id
        }
    }
`

interface Props {
    refetch?: (options?: any) => void
    user?: User
}

export default class DataView extends React.Component<Props> {
    private sendAttendanceReminderMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.sendAttendanceReminderMutator = new Mutator({
            mutation: SEND_ATTENDANCE_REMINDER_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { user } = this.props

        if (!user) {
            return null
        }

        const phoneNumbers = user.profile.phoneNumbers || []
        const { teacher } = user
        const paymentAddress = teacher && teacher.paymentAddress

        let isMfaConfigured = 'Nee'
        isMfaConfigured = user.profile.isMfaConfigured === true ? 'Ja' : 'Nee'

        return (
            <ContentView hasFixedActionBar={true}>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup title={`Persoonlijke gegevens`}>
                            <Field title={`Naam`}>{user.profile.name}</Field>
                            <Field title={`Geslacht`}>{translateType('userGender', user.profile.gender)}</Field>
                        </FieldGroup>
                        <FieldGroup title={`MFA`}>
                            <Field title={`geconfigureerd`}>{isMfaConfigured}</Field>
                        </FieldGroup>
                        <FieldGroup title={`Contactgegevens`}>
                            <Field title={`Telefoonnummer`}>
                                <ReadablePhoneNumbers phoneNumbers={phoneNumbers} />
                            </Field>
                            <Field title={`Primair e-mailadres`}>{user.email}</Field>
                            {user.email && !user.lastActivityAt && !user.invitedAt && (
                                // todo: think about how we could retrigger cognito
                                <Field title="&nbsp;">
                                    <ModalManager
                                        render={openModal => (
                                            <Button onClick={openModal}>{'Toegang tot het systeem verlenen'}</Button>
                                        )}
                                        getModal={closeModal => (
                                            <CenterModal
                                                onClose={closeModal}
                                                title={`Toegang verlenen aan ${user.profile.name}`}
                                            >
                                                <UserInvitationForm
                                                    user={user}
                                                    onSuccess={() => {
                                                        closeModal()
                                                        this.userInvited()
                                                    }}
                                                    onCancel={closeModal}
                                                />
                                            </CenterModal>
                                        )}
                                    />
                                </Field>
                            )}
                            <Field title={`Secundair e-mailadres`}>{user.profile.alternativeEmail}</Field>
                            <AddressFieldGroup user={user} />
                        </FieldGroup>
                        <FieldGroup title={`Betalingsgegevens`}>
                            <Field title={`Adresgegevens als factuuradres gebruiken`}>
                                {paymentAddress ? 'Nee' : 'Ja'}
                            </Field>
                            {paymentAddress && (
                                <Field title={`Factuuradres`}>
                                    {paymentAddress && <ReadableAddress address={paymentAddress} showCountry={true} />}
                                </Field>
                            )}
                            <Field title={`IBAN`}>{teacher && teacher.paymentIban}</Field>
                        </FieldGroup>
                        <FieldGroup title={`Bedrijfgegevens`}>
                            <Field title={`Is freelancer`}>{teacher && teacher.hasOwnCompany ? 'Ja' : 'Nee'}</Field>
                            {teacher && teacher.hasOwnCompany && (
                                <>
                                    <Field title={`Bedrijfsnaam`}>{teacher && teacher.companyName}</Field>
                                    <Field title={`KvK-nummer`}>{teacher && teacher.companyCocNumber}</Field>
                                    <Field title={`BTW-nummer`}>{teacher && teacher.companyVatNumber}</Field>
                                </>
                            )}
                        </FieldGroup>
                        {this.renderRemainderFieldGroup(user)}
                    </FieldCollection>
                    <ActionBar
                        fixed={true}
                        getButtons={() => (
                            <List horizontal={true}>
                                <ListItem>
                                    <ModalManager
                                        render={openModal =>
                                            viewerHasRole(UserRole.Admin) && (
                                                <Button styleOverride={`secondary-edit`} onClick={openModal}>
                                                    Wijzigen naar medewerker
                                                </Button>
                                            )
                                        }
                                        getModal={closeModal => (
                                            <CenterModal onClose={closeModal} title={`Wijzigen naar medewerker`}>
                                                <EmployeeExternalTeacherForm
                                                    type="teacherToEmployee"
                                                    onSubmitSuccess={() => {
                                                        closeModal()
                                                        browserHistory.push(`/users/employees/${user._id}`)
                                                    }}
                                                    onCancel={closeModal}
                                                    user={user}
                                                />
                                            </CenterModal>
                                        )}
                                    />
                                </ListItem>
                                <ListItem right={true}>
                                    <Button
                                        type={`edit`}
                                        onClick={() => browserHistory.push(`/users/teachers/${user._id}/edit`)}
                                    >
                                        Bewerken
                                    </Button>
                                </ListItem>
                            </List>
                        )}
                    />
                </Wrap>
            </ContentView>
        )
    }

    private renderRemainderFieldGroup = (user: User) => {
        const { teacher } = user
        const { loading: isSendingAttendanceReminder } = this.sendAttendanceReminderMutator

        const teacherIsNT2Certified = teacher && teacher.isNT2Certified
        const NT2Certificates = teacher && teacher.NT2Certificates
        const NT2CertifiedDate = teacher && teacher.NT2CertifiedDate

        const teacherHasLessonCompetence = teacher && teacher.lessonCompetence
        const lessonCompetenceCertificates = teacher && teacher.lessonCompetenceCertificates
        const lessonCompetenceDate = teacher && teacher.lessonCompetenceDate

        return (
            <FieldGroup title={`Overige`}>
                <Field title={`Personeelsnummer`}>{user.employee && user.employee.hrNumber}</Field>

                <Field title={`Functie(s)`}>
                    {(user.roles || []).map(role => translateType('mutableRole', role)).join(', ')}
                </Field>

                <Field title={`Gecertificeerd NT2 docent?`}>
                    {teacher && teacher.isNT2Certified ? 'Ja' : 'Nee'}

                    <List>
                        {teacherIsNT2Certified && NT2CertifiedDate && (
                            <ListItem>
                                <Subtle isBlockElement={true}>Certificeringsdatum</Subtle>
                                <ReadableDate date={NT2CertifiedDate} />
                            </ListItem>
                        )}

                        {teacherIsNT2Certified && NT2Certificates && (
                            <ListItem>{this.renderCertificateFields('NT2 certificaat', NT2Certificates)}</ListItem>
                        )}
                    </List>
                </Field>

                <Field title={`Lesbevoegdheid`}>
                    {teacher && teacher.lessonCompetence ? 'Ja' : 'Nee'}

                    <List>
                        {teacherIsNT2Certified && lessonCompetenceDate && (
                            <ListItem>
                                <Subtle isBlockElement={true}>Certificeringsdatum</Subtle>
                                <ReadableDate date={lessonCompetenceDate} />
                            </ListItem>
                        )}

                        {teacherHasLessonCompetence && lessonCompetenceCertificates && (
                            <ListItem>
                                {this.renderCertificateFields(
                                    'Lesbevoegheid certificaat',
                                    lessonCompetenceCertificates
                                )}
                            </ListItem>
                        )}
                    </List>
                </Field>
                <Field title={`Notities`}>{user.profile.notes}</Field>
                <Field title={`Status`}>{teacher && teacher.isActive ? 'Actief' : 'Non-actief'}</Field>
                {teacher && teacher.isActive && (
                    <Field title={`Presentie-herinnering`}>
                        <Button onClick={this.sendAttendanceReminder} isLoading={isSendingAttendanceReminder}>
                            Verstuur presentie herinnering
                        </Button>
                        <Hint>
                            <Bold>Let op:</Bold> deze e-mail wordt al dagelijks om 5:30 verstuurd.
                        </Hint>
                        <Hint>
                            Met deze knop wordt er naar de docent een herinneringsmail gestuurd voor het invoeren van de
                            presenties, mits de docent lessen heeft waar presenties van missen. Er worden maximaal 10
                            lessen in de e-mail getoond, alleen van lessen die langer dan 24 uur geleden zijn (nieuwste
                            bovenaan).
                        </Hint>
                    </Field>
                )}
            </FieldGroup>
        )
    }

    private renderCertificateFields = (title: string, certificates: CertificateFile[]) => {
        return (
            <>
                <Subtle isBlockElement={true}>{certificates.length === 1 ? 'Certificaat' : 'Certificaten'}</Subtle>
                <List>
                    {certificates.map(file => {
                        const { _id, fileName } = file

                        return file.isPDF ? (
                            <ListItem key={`file-${_id}`}>
                                <ModalManager
                                    render={openModal => (
                                        <Button onClick={openModal} linkStyle={`default`}>
                                            {fileName}
                                        </Button>
                                    )}
                                    getModal={closeModal => (
                                        <PdfModal
                                            title={fileName}
                                            fileName={fileName}
                                            getFileId={() => _id}
                                            onClose={closeModal}
                                        />
                                    )}
                                />
                            </ListItem>
                        ) : (
                            <ListItem key={`file-${_id}`}>
                                <Button linkStyle={`default`} onClick={() => downloadFile(_id, fileName)}>
                                    {fileName}
                                </Button>
                            </ListItem>
                        )
                    })}
                </List>
            </>
        )
    }

    private userInvited = () => {
        const { refetch } = this.props

        if (refetch) {
            refetch({ silent: true })
        }
    }

    private sendAttendanceReminder = async () => {
        const { user } = this.props

        if (user) {
            const result = await this.sendAttendanceReminderMutator.mutate({
                teacherUserId: user._id,
            })

            if (result && result.users_sendAttendanceReminder) {
                toast.success('Herinnering verstuurd.')
            }
        }
    }
}
