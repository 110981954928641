import * as React from 'react'

import { Form, TableWrap, TableHeaderItem, TableHeader, DrawerHeader, FieldCollection } from '~/components'

import { GroupExamsModalTable } from '~/components/tables/GroupExamsModalTable/GroupExamsModalTable'
import { LessonUser } from '~/types/User'
import { ResultInput, PlannedAbilityExam, Ability } from '~/types/Exam'
import { Mutator } from '~/utils'
import gql from 'graphql-tag'
import { LearnerLevelWithAlphaInput } from '~/components/LearnerLevelWithAlphaInput'
import transformFormFields from '~/utils/transformFormFields'
import { TableView } from '~/components/TableView'
import { ContentView } from '~/components/ContentView'
import { GroupExamFormTableRow } from '~/components/Group/Exams/GroupExamFormTableRow'
import DrawerFooter from '~/components/DrawerFooter'

interface FormFields {
    results: ResultInput[]
}

type FormSubmit<Fields> = (event: React.SyntheticEvent<HTMLFormElement>, fields: Fields) => void

interface Props {
    onSubmitSuccess?: () => void
    onCancel?: () => void
    plannedAbilityExam: PlannedAbilityExam
    examAbilityDetails: Ability
}

const RESULT_MUTATION = gql`
    mutation _($results: [ResultInputType]) {
        result_enterMultiple(results: $results)
    }
`

export class GroupExamLevelForm extends React.Component<Props, {}> {
    private examAbilityMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.examAbilityMutator = new Mutator({
            mutation: RESULT_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onCancel, plannedAbilityExam } = this.props
        const { loading } = this.examAbilityMutator
        const examName = plannedAbilityExam.exam && plannedAbilityExam.exam.name

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <DrawerHeader
                        title={`${examName} (${this.getReadableAbility(plannedAbilityExam.examAbility)})`}
                        close={onCancel}
                    />
                    <ContentView>
                        <TableWrap>
                            <TableView needsPaddingForTagPicker={true}>
                                <GroupExamsModalTable type={`level`}>
                                    <TableHeader>
                                        <TableHeaderItem style={{ width: '27%' }}>Kandidaat</TableHeaderItem>
                                        <TableHeaderItem style={{ width: '250px' }}>Status</TableHeaderItem>
                                        <TableHeaderItem>Niveau-aanduiding</TableHeaderItem>
                                    </TableHeader>
                                    {this.renderTableRows()}
                                </GroupExamsModalTable>
                            </TableView>
                        </TableWrap>
                    </ContentView>
                </FieldCollection>
                <DrawerFooter close={onCancel} isLoading={loading} />
            </Form>
        )
    }

    private renderTableRows = () => {
        const { plannedAbilityExam } = this.props
        const { errors } = this.examAbilityMutator
        const plannedAbilityExamId = plannedAbilityExam._id
        const groupId = plannedAbilityExam.group && plannedAbilityExam.group._id
        const lessonUsers = plannedAbilityExam.lesson && plannedAbilityExam.lesson.lessonUsers

        if (!lessonUsers) {
            return null
        }

        return lessonUsers.map((lessonUser: LessonUser, i: number) => {
            const examAbilityResult = this.getCorrespondingResultForUserId(lessonUser.learnerUserId)
            const defaultValueForGrade = examAbilityResult && examAbilityResult.result && examAbilityResult.result.level
            const learner = lessonUser.learnerUser
            const inputBaseName = `results[${i}]`

            return (
                <GroupExamFormTableRow
                    key={lessonUser._id}
                    errors={errors}
                    groupId={groupId}
                    baseName={inputBaseName}
                    renderTableSpecificInput={() => (
                        <LearnerLevelWithAlphaInput
                            name={`${inputBaseName}.result.level`}
                            defaultValue={defaultValueForGrade}
                            placeholder={``}
                        />
                    )}
                    learner={learner}
                    examAbilityResult={examAbilityResult}
                    plannedAbilityExamId={plannedAbilityExamId}
                />
            )
        })
    }

    private getReadableAbility = (ability: string) => {
        if (ability === 'converse') {
            return 'Gespreksvaardigheid'
        } else if (ability === 'talk') {
            return 'Spreken'
        } else if (ability === 'write') {
            return 'Schrijven'
        } else if (ability === 'listen') {
            return 'Luisteren'
        } else if (ability === 'read') {
            return 'Lezen'
        }

        return null
    }

    private getCorrespondingResultForUserId = (userId: string) => {
        const { plannedAbilityExam } = this.props
        const defaultValues = plannedAbilityExam.results
        const results = defaultValues && defaultValues.filter(result => result.learnerUserId === userId)

        return results && results[0]
    }

    private onSubmit: FormSubmit<FormFields> = async (event, fields) => {
        event.preventDefault()
        const { onSubmitSuccess } = this.props

        const data = await this.examAbilityMutator.mutate({
            results: fields.results.map(result => {
                return transformFormFields(result, {
                    result: { value: (v: any) => transformFormFields(v) },
                })
            }),
        })

        const mutationSuccess = data && data.result_enterMultiple

        if (mutationSuccess && onSubmitSuccess) {
            onSubmitSuccess()
        }
    }
}
