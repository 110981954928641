import { ExamAbilityResult } from '~/types/Exam'
import { Group } from '~/types/Group'
import { Organization } from '~/types/Organization'
import { TimelineEvent } from '~/types/TimelineEvent'
import { TwinfieldCustomer, DUODeclaration, Invoice } from '~/types/Invoice'
import { LearnerLevelWithAlpha, LearnerLevel } from '~/types/LearnerLevel'
import { Module } from '~/types/Module'
import { Location } from '~/types/Location'
import { FinalExamNeeded, FinalExam } from '~/types/FinalExam'
import { UserLearnerHour } from '~/types/UserLearnerHour'
import { AdviceReport } from './AdviceReports'
import { InflowMoment } from './InflowMoments'
import { Contract } from './Contracts'
import { LessonReasonOfAbsence } from '~/types/Lesson'
import { IntegrationLawTypes, PaymentByType } from '~/components/User/Learner/UserLearnerAddInvoicingForm'

export enum PrecisionDatePrecision {
    Year = 'YEAR',
    Month = 'MONTH',
    Day = 'DAY',
    Hour = 'HOUR',
    Minute = 'MINUTE',
    Second = 'SECOND',
}

export interface PrecisionDate {
    date?: Date
    precision?: PrecisionDatePrecision
}

export interface ObligedCitizenshipEducationEndDateExtensionDocument {
    _id: string
    fileName?: string
    isPDF?: boolean
    createdAt?: Date
    createdByUser?: User
}

export interface ObligedCitizenshipEducationEndDateExtensionType {
    _id: string
    date?: any
    description?: string
    document?: ObligedCitizenshipEducationEndDateExtensionDocument
    createdAt?: any
    createdByUser?: User
    fileToUploadName?: string
}

export interface UserDocument {
    _id: string
    fileName?: string
    isPDF?: boolean
    type?: string
    createdAt?: Date
    createdByUser?: User
}

export interface PhoneNumber {
    _id: string
    country?: string
    phoneNumber?: string
    info?: string
    internationalPhoneNumber?: string
}

export interface Address {
    _id: string
    nl?: AddressNLType
    other?: AddressOtherType
    country?: string
    phoneNumbers?: PhoneNumber[]
    city?: string
}

export interface AddressNLType {
    number: number
    numberAddition: string
    zipcode: string
    extraInfo: AddressNLExtraInfoType
    manualExtraInfo: AddressNLExtraInfoType
    validatedExtraInfo: AddressNLExtraInfoType
}

export interface AddressNLExtraInfoType {
    street: string
    city: string
    municipality: string
}

export interface AddressOtherType {
    addressLine1: string
    addressLine2: string
}

export interface CalenderEvent {
    _id: string
    type?: string
    title?: string
    startDate?: Date
    endDate?: Date
    startWeekDay?: number
    endWeekDay?: number
}

export enum UserRole {
    Admin = 'ADMIN',
    Employee = 'EMPLOYEE',
    Teacher = 'TEACHER',
    Intaker = 'INTAKER',
    OrganizationContact = 'ORGANIZATION_CONTACT',
    Learner = 'LEARNER',

    ProjectManager = 'PROJECT_MANAGER',
    ProjectAssistant = 'PROJECT_ASSISTANT',
    TeamLeader = 'TEAM_LEADER',
    Receptionist = 'RECEPTIONIST',
    InternalTeacher = 'INTERNAL_TEACHER',
    Management = 'MANAGEMENT',
    OfficeAssistant = 'OFFICE_ASSISTENT',
    Accountant = 'ACCOUNTANT',
    Telephonist = 'TELEPHONIST',
    FinancialEmployee = 'FINANCIAL_EMPLOYEE',
    FacilityEmployee = 'FACILITY_EMPLOYEE',
    OnCallEmployee = 'ON_CALL_EMPLOYEE',
    Manager = 'MANAGER',
    ItEmployee = 'IT_EMPLOYEE',
    AccountManager = 'ACCOUNT_MANAGER',
    IntakeEmployee = 'INTAKE_EMPLOYEE',
    HREmployee = 'HR_EMPLOYEE',
}

export interface User {
    _id: string
    email?: string
    emailForCommunication?: string
    roles?: UserRole[]
    isLearner?: boolean
    isEmployee?: boolean
    isAdmin?: boolean
    isExternalTeacher?: boolean
    isIntaker?: boolean
    isOrganizationContact?: boolean
    isFinancial?: boolean
    lastActivityAt?: Date
    invitedAt?: Date
    hasExpiredInvoices?: boolean
    mayHaveNewInvoices?: boolean
    hasInvoicingEnabled?: boolean
    profile: UserProfile
    teacher?: UserTeacher
    learner?: UserLearner
    employee?: UserEmployee
    organizationContact?: UserOrganizationContact
    documents?: UserDocument[]
    results?: ExamAbilityResult[]
}

export enum GroupUserRole {
    Learner = 'LEARNER',
    Teacher = 'TEACHER',
    Employee = 'EMPLOYEE',
}

export interface GroupUser {
    _id: string
    user?: User
    role?: GroupUserRole
    learnerAttendanceRate?: number
    lessonInvitePdfDownloadedAt?: Date
    lessonInviteEmailSentAt?: Date
    hasAttendances?: boolean
    hasFinishedGroup?: boolean
    isGroupParticipationInvoiceActivatable?: boolean
    hasSignedContractForEveryLessonInGroup?: boolean
    isNowEnrolled?: boolean
    hasLearnerFutureEnrollment?: boolean
    hasLearnerFinalEnrollment?: boolean
    enrollments?: GroupUserEnrollment[]
    hasAttendancesAfterLastEnrollment?: boolean
    hasInvoicesAfterLastEnrollment?: boolean
}

export enum GroupUserEnrollmentType {
    Enrollment = 'Enrollment',
    Disenrollment = 'Disenrollment',
}

export enum GroupUserRemovedReason {
    FinishedSuccesful = 'FinishedSuccesful',
    FinishedUnsuccesful = 'FinishedUnsuccesful',
    EarlyDisenrollmentNotSatisfiedWithTopTaal = 'EarlyDisenrollmentNotSatisfiedWithTopTaal',
    EarlyDisenrollmentMovedHouse = 'EarlyDisenrollmentMovedHouse',
    EarlyDisenrollmentWentToOtherSchool = 'EarlyDisenrollmentWentToOtherSchool',
    EarlyDisenrollmentUnreachable = 'EarlyDisenrollmentUnreachable',
    EarlyDisenrollmentFoundWork = 'EarlyDisenrollmentFoundWork',
    EarlyDisenrollmentLevelUnsuitable = 'EarlyDisenrollmentLevelUnsuitable',
    EarlyDisenrollmentUnavailable = 'EarlyDisenrollmentUnavailable',
    EarlyDisenrollmentMedical = 'EarlyDisenrollmentMedical',
    EarlyDisenrollmentMotivation = 'EarlyDisenrollmentMotivation',
    EarlyDisenrollmentLeavingEmployer = 'EarlyDisenrollmentLeavingEmployer',
    EarlyDisenrollmentOtherPersonalReasons = 'EarlyDisenrollmentOtherPersonalReasons',
    ReturnBeforeStart = 'ReturnBeforeStart',
    ExemptionRoute = 'ExemptionRoute',
    OnHold = 'OnHold',
    Other = 'Other',
    AlmostSuccessfull = 'AlmostSuccessfull',
}

// (bijna) geslaagd

// ontheffingsroute

// on hold

// overige

export interface GroupUserEnrollment {
    _id: string
    role?: GroupUserRole
    type?: GroupUserEnrollmentType
    removedReason?: GroupUserRemovedReason
    removedInfo?: string
    date?: Date
    createdAt?: Date
    createdByUser?: User
    updatedAt?: Date
    updatedByUser?: User
}

export interface UserProfile {
    hasEmail?: boolean
    initials?: string
    firstName?: string
    surName?: string
    surNamePrefix?: string
    givenName?: string
    fullSurName?: string
    name?: string
    nameAbbreviation?: string
    gender?: string
    dateOfBirth?: string
    countryOfBirth?: string
    cityOfBirth?: string
    phoneNumbers?: PhoneNumber[]
    alternativeEmail?: string
    address?: Address
    visitAddress?: Address
    countriesOfNationality?: string[]
    hasNoCountryOfNationality?: boolean
    countryOfOrigin?: string
    bsn?: string
    notes?: string
    timelineEvents?: TimelineEvent[]

    // mfa related fields
    isMfaConfigured?: boolean
    cognitoUserId?: string
    mfaMobileNumber?: string
}

export interface NT2 {
    year?: number
    duration?: string
    level?: LearnerLevelWithAlpha
    organizationName?: string
    courseMaterials?: string
}

type UserTeacherAvailabilityType = 'FULL' | 'PARTIAL' | 'NONE'

export interface UserTeacherAvailability {
    date: string
    availabilityMorning: UserTeacherAvailabilityType
    availabilityAfternoon: UserTeacherAvailabilityType
    availabilityEvening: UserTeacherAvailabilityType
}

export interface CertificateFile {
    _id: string
    fileName: string
    isPDF: boolean
}

export interface UserTeacher {
    isActive?: boolean
    groups?: Group[]
    paymentAddress?: Address
    paymentIban?: string
    isNT2Certified?: boolean
    NT2CertifiedDate?: Date
    NT2Certificates?: CertificateFile[]
    lessonCompetence?: boolean
    lessonCompetenceDate?: Date
    lessonCompetenceCertificates?: CertificateFile[]
    hasOwnCompany?: boolean
    companyName?: string
    companyCocNumber?: string
    companyVatNumber?: string
    intakes?: User[]
    availabilityCalendarEvents?: CalenderEvent[]
    availability?: UserTeacherAvailability[]
}

export interface UserLearnerEdisa {
    edisaDate?: string
}

export interface UserLearnerOrganization {
    organization?: Organization
    organizationContactUser?: User
    organizationFileNumber?: string
}

interface UserLearnerSignup {
    _id: string
    signupDate: Date
    enrollmentType: LearnerEnrollmentType
}

export interface UserLearnerHoursSummary {
    hoursCitizenship?: number
    hoursCitizenshipOfWhichONA?: number
    hoursAlfa?: number
    hoursOther?: number
    hoursOtherOfWhichONA?: number
    hoursTotal?: number
}

export interface LearnerAbilityLevel {
    level: LearnerLevelWithAlpha
    accomplishedAt: Date
}

export interface LatestAbilityLevels {
    converse: LearnerAbilityLevel
    talk: LearnerAbilityLevel
    write: LearnerAbilityLevel
    listen: LearnerAbilityLevel
    read: LearnerAbilityLevel
}

export interface UserLearner {
    isActive?: boolean
    status?: string
    attendanceRate?: number
    groups?: Group[]
    organizations?: UserLearnerOrganization[]
    organizationContactUser?: User
    hasPrivatePayment?: boolean
    isIntegrationCourse?: boolean
    integrationLawType?: IntegrationLawTypes
    paymentBy?: PaymentByType
    twinfieldCustomer?: TwinfieldCustomer
    canTwinfieldCustomerCodeChange?: boolean
    privatePaymentIsDUO?: boolean
    privatePaymentDUOHasApprovedFund?: boolean
    privatePaymentDUOInitialRemainingFund?: number
    privatePaymentDUORemainingLifeFund?: number
    canPrivatePaymentDUOInitialRemainingLifeFundBeChangedByContextUser?: boolean
    privatePaymentAddress?: Address
    isInvoicingByDUOActive?: boolean
    oldFileNumber?: string
    immigratedAt?: PrecisionDate
    isCitizenshipEducationObliged?: boolean
    obligedCitizenshipEducationStartDate?: PrecisionDate
    obligedCitizenshipEducationEndDate?: PrecisionDate
    obligedCitizenshipEducationInitialEndDate?: Date
    socialStatus?: string
    socialStatusExtraInfo?: string
    familyCompositionLanguageExposure?: string
    nativeLanguages?: string[]
    homeLanguages?: string[]
    otherLanguages?: string[]
    englishAsSupportLanguage?: string
    workExperienceCountryOfOrigin?: string
    workExperienceLocal?: string
    workLiteracy?: string
    workLiteracyLevel?: string
    workLiteracyInfo?: string
    currentOccupationMainLanguages?: string[]
    currentOccupationVocalInteraction?: string
    currentOccupationVocalInteractionQuality?: string
    currentOccupationVocalInteractionInfo?: string
    currentOccupationStartDate?: PrecisionDate
    currentOccupationResponsibilities?: string
    currentOccupationFunction?: string
    highestEducationCountryOfOrigin?: string
    highestEducationCountryOfOriginYear?: number
    highestEducationLocal?: string
    highestEducationLocalYear?: number
    educationDesire?: string
    educationExtraInfo?: string
    computerSkill?: string
    NT2HasAttempted?: boolean
    NT2Info?: string
    NT2?: NT2[]
    intakeDate?: Date
    intakeUsers?: User[]
    intakeLiteracy?: string
    intakeLiteracyNativeLanguage?: string
    intakeGrades?: IntakeGrades
    latestAbilityLevels?: LatestAbilityLevels
    // intakeGradesReading?: LearnerLevelWithAlpha
    // intakeGradesWriting?: LearnerLevelWithAlpha
    // intakeGradesListening?: LearnerLevel
    // intakeGradesTalking?: LearnerLevel
    // intakeGradesConversations?: LearnerLevel
    legacyIntakeGrades?: LegacyIntakeGrades
    intakeReadingExamScore?: number
    intakeReadingExamScoreTotal?: number
    intakeGradesLearnability?: number
    intakeMotivation?: string
    adviceModule?: Module
    adviceModuleFilterByProjectId?: string
    adviceModuleFilterByProgramId?: string
    adviceModuleInfo?: string
    intakeGoals?: string[]
    intakeOtherGoal?: string
    intakeGoalsAdditionalInfo?: string
    preferredLocations?: Location[]
    intakeResultsInfo?: string
    howDoYouKnowTopTaal?: string
    availabilityCalendarEvents?: CalenderEvent[]
    edisa?: UserLearnerEdisa
    invoices?: Invoice[]
    latestInternalDUODeclarationsUpdateDate?: Date
    DUODeclarations?: DUODeclaration[]
    finalExamNeeded?: FinalExamNeeded
    hours?: UserLearnerHour[]
    hoursSummary?: UserLearnerHoursSummary
    hoursPresent?: number
    eLearningHours?: number
    examDignityDate?: Date
    finalExams?: FinalExam[]
    inflowMoments?: InflowMoment[]
    signups: UserLearnerSignup[]
    enrollmentType?: LearnerEnrollmentType
    enrollmentDate?: Date
    adviceReports?: AdviceReport[]
    removedAt?: Date
    removedReason?: GroupUserRemovedReason
    removedInfo?: string
    removedByUser?: User
    hasFinishedGroup?: boolean
    contractCount?: number
    contracts?: Contract[]
    inflowMomentsNoShowStreak?: number
    isAvailableForInflow?: boolean
    hasInflowMomentsAfterEnrollmentDate?: boolean
    isInflowOnHold?: boolean
}

export enum LearnerEnrollmentType {
    Intake = 'intake',
    Assessment = 'assessment',
}
export interface UserEmployee {
    isActive?: boolean
    groups?: Group[]
    hrNumber?: string
}

export interface UserOrganizationContact {
    organization?: Organization
    hasAcceptedAgreement?: boolean
}

export interface LessonUser {
    _id: string
    attendance: string
    reasonOfAbsence?: LessonReasonOfAbsence
    attendanceNote: string
    attendanceSetByUser: User
    attendanceSetAt: string
    learnerUserId: string
    learnerUser: User
    isRemovedMember: boolean
}

export interface IntakeGrades {
    levelReading?: LearnerLevelWithAlpha
    levelWriting?: LearnerLevelWithAlpha
    levelListening?: LearnerLevel
    levelTalking?: LearnerLevel
    levelConversations?: LearnerLevel
}
export interface LegacyIntakeGrades {
    reading?: LearnerLevelWithAlpha
    writing?: LearnerLevelWithAlpha
    listening?: LearnerLevel
    talking?: LearnerLevel
    conversations?: LearnerLevel
}
